//#region firebase

export type invalidEmail = 'auth/invalid-email';
export type accountDissabled = 'auth/user-disabled';
export type userNotFound = 'auth/user-not-found';
export type wrongPassword = 'auth/wrong-password';
export type expiredCode = 'auth/expired-action-code';
export type invalidCode = 'auth/invalid-action-code';
export type weakPassword = 'auth/weak-password';
export type emailAlreadyInUse = 'auth/email-already-in-use';

export type authErrorType =
    | invalidEmail
    | accountDissabled
    | userNotFound
    | wrongPassword;

export type resetErrorType = invalidEmail | userNotFound;

export type confirmResetErrorType =
    | accountDissabled
    | userNotFound
    | expiredCode
    | invalidCode
    | weakPassword;

export type createUserErrorType =
    | invalidEmail
    | emailAlreadyInUse
    | weakPassword;

export interface CustomClaims {
    company: string;
    isAdmin: boolean;
    isSuperAdmin: boolean;
}

//#endregion

//#region data model

export interface WithId {
    uid: string;
}

export interface Chapter extends WithId {
    number: number;
    images?: string[];
    date?: Date;
    read: boolean;
    next: string | null;
    prev: string | null;
}

// eslint-disable-next-line no-shadow
export enum Parsers {
    READM = 0,
    HEROMANHUA = 1,
    ASTRALLIBRARY = 2,
    NONSTOPSCANS = 3,
    ZEROSCANS = 4,
    READMANHUA = 5,
}

export interface NewManga {
    title: string;
    cover?: string;
    current_chapter: number | null;
    first_chapter?: number;
    last_chapter?: number;
    chapters?: Chapter[];
    sources: { parser: Parsers; args: { [key: string]: string } }[];
}

export interface Manga extends NewManga, WithId {}

export type UrlBuilder = (chapter: number) => string;

export interface Source {
    name: string;
    getUrlBuilder: (args: { [key: string]: string }) => UrlBuilder;
    enum: number;
}

export interface NoChildren {
    children?: undefined;
}

//#endregion

//#region cloud functions

export interface ParseChapterParams {
    manga_id: string;
    id: string;
    url: string;
    parser: Parsers;
}

export type ParseChapterResult = string[];

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface CrawlMangaParams extends ParseChapterParams {}

export type CrawlMangaResult = string[];

export interface ParseChaptersParams {
    manga: string;
    chapters: { id: string; url: string }[];
    parser: Parsers;
}

export interface ParseChaptersResult {
    id: string;
    images: string[];
}

export interface ParseMangaParams {
    url: string;
    parseChapters?: boolean;
}

export interface ParseMangaResult extends Manga {
    cover: string;
}

export interface UpdateChaptersReadParams {
    manga_id: string;
    value: boolean;
}

export type UpdateChaptersReadResult = void;

export interface UpdateChapterReadParams {
    manga_id: string;
    chapter_id: string;
    value: boolean;
    retroactive?: boolean;
}

export type UpdateChapterReadResult = void;

//#endregion
