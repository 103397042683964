import firebase from 'firebase/app';
import 'firebase/firestore';
import 'firebase/auth';
import 'firebase/storage';
import 'firebase/functions';

const firebaseConfig = {
    apiKey: 'AIzaSyBQ7GbLiTENw3UQfsLwCe0cMzgdV_dswUI',
    authDomain: 'personalsite-ae3f9.firebaseapp.com',
    databaseURL: 'https://personalsite-ae3f9.firebaseio.com',
    projectId: 'personalsite-ae3f9',
    storageBucket: 'personalsite-ae3f9.appspot.com',
    messagingSenderId: '800769944896',
    appId: '1:800769944896:web:99ee30777c74198e935ea5',
};

firebase.initializeApp(firebaseConfig);

export const auth = firebase.auth();
export const firestore = firebase.firestore();
export const storage = firebase.storage();
export const functions = firebase.functions();

if (process.env.NODE_ENV === 'development') {
    functions.useEmulator('localhost', 5001);
    firestore.useEmulator('localhost', 8080);
}
