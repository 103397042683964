import React from 'react';
import { Route, Redirect, RouteProps } from 'react-router-dom';

import { isAuthenticated } from '../utils/store';

interface PrivateRouteProps extends RouteProps {
    redirectTo?: string;
}

export const PrivateRoute: React.FC<PrivateRouteProps> = ({
    redirectTo,
    children,
    ...rest
}) => {
    const { location } = rest;

    if (isAuthenticated()) {
        return <Route {...rest}>{children}</Route>;
    }

    return (
        <Redirect
            to={{
                pathname: redirectTo ?? '/login',
                state: { from: location },
            }}
        />
    );
};
