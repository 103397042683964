import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

import Navbar from 'react-bootstrap/Navbar';

import { Bookshelf } from './bookshelf';

export const NavBar: React.FC = () => {
    return (
        <StyledBar bg="dark" variant="dark" fixed="top">
            <Link to="/">
                <Bookshelf width="30" height="30" />
            </Link>
        </StyledBar>
    );
};

const StyledBar = styled(Navbar)`
    padding: 0.5rem 1rem;
`;
