import { useState, useEffect } from 'react';

/**  Remember to always pass a function as returned from **useCallback** or it will load every single time */
export const useAsyncLoad = <T>(
    loadFunction: () => Promise<T | null>,
    delayLoad = false,
) => {
    const [value, setValue] = useState<T | null>(null);
    const [loading, setLoading] = useState<boolean>(true);
    const [error, setError] = useState<boolean>(false);

    useEffect(() => {
        setValue(null);
        setLoading(true);
        setError(false);

        const loadData = async () => {
            let res;
            try {
                res = await loadFunction();
            } catch (e) {
                console.warn(e);
            }
            if (res) {
                setValue(res);
            }
            setError(!res);
            setLoading(false);
        };

        if (!delayLoad) {
            void loadData();
        }
    }, [delayLoad, loadFunction]);

    return [value, loading, error, setValue, setLoading, setError] as const;
};
