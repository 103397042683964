import React from 'react';
import styled, { css } from 'styled-components';

import Spinner, { SpinnerProps } from 'react-bootstrap/Spinner';

interface LoadingOverlayProps extends SpinnerProps {
    show: boolean;
    transparent?: boolean;
}

export const LoadingOverlay: React.FC<LoadingOverlayProps> = ({
    show,
    variant,
    transparent,
    ...rest
}) => {
    variant = variant ?? 'primary';
    return (
        <Container $show={show} $transparent={transparent}>
            <Spinner variant={variant} {...rest} />
        </Container>
    );
};

//#region styled

const Container = styled.div.attrs((_props) => ({
    className: 'center-content',
}))<{ $show: boolean; $transparent?: boolean }>`
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    ${(props) =>
        !props.$show &&
        css`
            display: none;
        `}
    ${(props) =>
        !props.$transparent &&
        css`
            background-color: #35353575;
        `}
`;

//#endregion
