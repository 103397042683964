import React, { useCallback, useState } from 'react';
import cx from 'classnames';

import Button from 'react-bootstrap/Button';
import { IoMdAddCircleOutline } from 'react-icons/io';
import { BiImport } from 'react-icons/bi';

import { useAsyncLoad } from 'utils/useAsyncLoad';
import { LoadingOverlay } from 'components/loadingOverlay';
import { getManga } from 'utils/store';
import { MangaCard } from 'components/manga/card';
import { ModalForm, FormFunction } from 'components/modalForm';
import { MangaFormId, MangaForm } from 'components/manga/form';
import {
    MangaImportForm,
    MangaImportFormId,
} from 'components/manga/importForm';
import { Manga } from 'lib/types';

export const Home: React.FC = () => {
    const loadFn = useCallback(async () => {
        return await getManga();
    }, []);

    const [manga, loading, _error, setManga] = useAsyncLoad(loadFn);
    const [showModal, setShowModal] = useState<boolean>(false);
    const [imoportModal, setImportModal] = useState<boolean>(false);

    const formFunction: FormFunction<Manga> = useCallback(
        (args) => {
            if (imoportModal) {
                return {
                    node: <MangaImportForm {...args} />,
                    formId: MangaImportFormId,
                };
            }
            return {
                node: <MangaForm {...args} />,
                formId: MangaFormId,
            };
        },
        [imoportModal],
    );

    const handleShow = () => {
        setShowModal(true);
    };

    const handleShowImport = () => {
        setImportModal(true);
        setShowModal(true);
    };

    const handleClose = () => {
        setShowModal(false);
        setImportModal(false);
    };

    const onFormSuccess = (data: Manga) => {
        if (!manga) {
            setManga([data]);
            return;
        }
        const new_manga = [...manga];
        new_manga.push(data);
        setManga(new_manga);
        handleClose();
    };

    return (
        <>
            <div className={'text-right'}>
                <Button variant="link" onClick={handleShowImport}>
                    <BiImport size={'1.5em'} />
                </Button>
                <Button onClick={handleShow} variant="link">
                    <IoMdAddCircleOutline size="1.5em" />
                </Button>
            </div>
            <div className={cx('d-flex', 'flex-wrap')}>
                {manga?.map((m) => (
                    <MangaCard manga={m} key={m.uid} />
                ))}
            </div>
            <LoadingOverlay show={loading} animation="grow" />
            <ModalForm<Manga>
                editData={undefined}
                formFunction={formFunction}
                show={showModal}
                title="New Manga"
                onClose={handleClose}
                onSubmitSuccess={onFormSuccess}
            />
        </>
    );
};
