import React, { useCallback, useState } from 'react';
import styled, { css, keyframes } from 'styled-components';
import cx from 'classnames';
import { useHistory } from 'react-router-dom';

import { MdSync, MdSyncProblem } from 'react-icons/md';
import { AiOutlineCloudDownload } from 'react-icons/ai';
import { VscBook } from 'react-icons/vsc';
import Card from 'react-bootstrap/Card';

import { Cover } from 'components/cover';
import { getCoverUrl } from 'utils/store';
import { Manga, NoChildren } from 'lib/types';
import { IconButton } from 'components/iconButton';
import { parserMap } from 'utils/parsers';
import { parseChapters } from 'utils/cloud_functions';

export interface MangaCardProps extends NoChildren {
    manga: Manga;
}

export const MangaCard: React.FC<MangaCardProps> = (props) => {
    const [manga, setManga] = useState<Manga>(props.manga);
    const [syncing, setSyncing] = useState<boolean>(false);
    const [syncingError, setSyncingError] = useState<boolean>(false);

    const loadFn = useCallback(async () => {
        if (!manga.cover) {
            return null;
        }

        return await getCoverUrl(manga.cover);
    }, [manga.cover]);

    // eslint-disable-next-line no-shadow
    const history = useHistory();

    const navigateToManga = () => {
        history.push(`manga/${manga.uid}`);
    };

    const unread_chapters: boolean =
        manga.current_chapter === null ||
        (manga.last_chapter !== undefined &&
            manga.last_chapter > manga.current_chapter);

    const getNextChapter = () => {
        if (manga.current_chapter) {
            if (manga.current_chapter === manga.last_chapter) {
                return undefined;
            }
            return manga.current_chapter;
        }
        return manga.first_chapter;
    };

    const goToNextChapter = () => {
        history.push(`manga/${manga.uid}/${getNextChapter() ?? ''}`);
    };

    const onSyncButtonClick = async () => {
        setSyncing(true);
        await syncManga();
        setSyncing(false);
    };

    const syncManga = async () => {
        if (!manga?.chapters) {
            return;
        }

        const pending = manga.chapters.filter(
            (ch) => !ch.images || ch.images.length === 0,
        );

        if (pending.length === 0) {
            return;
        }

        const source = manga.sources[0];
        const urlBuilder = parserMap[source.parser].getUrlBuilder(source.args);
        const chapters = await parseChapters({
            manga: manga.uid,
            chapters: pending.map((ch) => ({
                id: ch.uid,
                url: urlBuilder(ch.number),
            })),
            parser: source.parser,
        });

        if (!chapters) {
            setSyncingError(true);
            return;
        }

        const new_manga = { ...manga };
        let idx = 0;
        chapters.forEach((ch) => {
            while (new_manga.chapters?.[idx].uid !== ch.id) {
                idx++;
            }
            new_manga.chapters[idx].images = ch.images;
        });

        setManga(new_manga);
    };

    const toolIconSize = '20px';

    return (
        <StyledCard>
            <StyledBody onClick={navigateToManga}>
                <StyledCover loadingFn={loadFn} />
                <h5 className={cx('m-0', 'truncate')}>{manga.title}</h5>
            </StyledBody>
            <ToolsContainer>
                <IconButton
                    className="on-hover disabled"
                    variant="link"
                    $slim
                    onClick={() => {
                        console.log('download click');
                    }}
                >
                    <AiOutlineCloudDownload size={toolIconSize} />
                </IconButton>
                <IconButton
                    className={!syncing && !syncingError && 'on-hover'}
                    variant="link"
                    $slim
                    onClick={onSyncButtonClick}
                >
                    {!syncingError && (
                        <SyncIcon size={toolIconSize} $animate={syncing} />
                    )}
                    {syncingError && (
                        <MdSyncProblem
                            size={toolIconSize}
                            className="text-red"
                        />
                    )}
                </IconButton>
                {getNextChapter() !== undefined && (
                    <IconButton
                        className="on-hover"
                        variant="link"
                        $slim
                        onClick={goToNextChapter}
                    >
                        <VscBook size={toolIconSize} />
                    </IconButton>
                )}
                {unread_chapters && (
                    <StyledBookmark
                        $not_started={manga.current_chapter === null}
                    />
                )}
            </ToolsContainer>
        </StyledCard>
    );
};

//#region styled

const card_width = '250px';
const StyledCard = styled(Card)`
    width: ${card_width};
    height: 70px;
    margin: 0 5px;
    cursor: pointer;
    overflow: hidden;
    @media (max-width: calc(${card_width} * 2.2)) {
        .card {
            width: 100%;
        }
    }

    .on-hover {
        display: none;
    }
    &:hover {
        .on-hover {
            display: initial;
        }
    }
`;

const StyledBody = styled(Card.Body)`
    display: flex;
    align-items: center;
    padding: 0;
    padding-right: 0.25rem;
`;

const StyledCover = styled(Cover)`
    margin-right: 1rem;
`;

const Bookmark: React.FC<React.HtmlHTMLAttributes<SVGElement>> = (props) => {
    return (
        <svg viewBox="0 0 26 38" xmlns="http://www.w3.org/2000/svg" {...props}>
            <path
                fill="currentColor"
                d="m26 38-13-6-13 6v-34c0-2.2 1.8-4 4-4h18c2.2 0 4 1.8 4 4z"
            />
        </svg>
    );
};

const ToolsContainer = styled.div`
    position: absolute;
    top: 0;
    right: 0;
    max-height: 30px;

    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    padding-right: 3px;
    gap: 3px;
`;

const StyledBookmark = styled(Bookmark)<{ $not_started: boolean }>`
    height: 25px;
    color: ${(props) => (props.$not_started ? '#3498db' : '#f44336')};
    width: auto;
`;

const rotate = keyframes`
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(-360deg);
  }
`;

const SyncIcon = styled(MdSync)<{ $animate: boolean }>`
    ${(props) =>
        props.$animate &&
        css`
            animation: ${rotate} 2s linear infinite;
        `}
`;

//#endregion
