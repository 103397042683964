import React from 'react';
import styled from 'styled-components';

import { Link } from 'react-router-dom';
import { Check } from 'components/check';
import { Chapter } from 'lib/types';

export interface ChapterCardProps {
    chapter: Chapter;
    onReadChange: (value: boolean) => void;
}

export const ChapterCard: React.FC<ChapterCardProps> = ({
    chapter,
    onReadChange,
}) => {
    return (
        <Container>
            <Check
                value={chapter.read}
                onValueChanged={onReadChange}
                alignment="left"
            />
            <Link to={(location) => `${location.pathname}/${chapter.uid}`}>
                {`Chapter ${chapter.number}`}
            </Link>
            <DateText className="text-muted">
                {chapter.date?.toLocaleDateString() ?? 'unknown'}
            </DateText>
        </Container>
    );
};

//#region styled

const Container = styled.div`
    display: grid;
    grid-template-columns: 1fr 3fr 1fr;
    margin: 0.25rem min(2rem, 5vw);
    grid-column-gap: 4rem;
`;

const DateText = styled.span`
    text-align: right;
`;

//#endregion
