import React from 'react';
import cx from 'classnames';

interface ErrorMessageProps {
    text?: string;
    show?: boolean;
    big?: boolean;
}

export const ErrorMessage: React.FC<ErrorMessageProps> = (props) => {
    if (!props.show) {
        return null;
    }
    return (
        <div className={cx('text-center', 'reset-lh')}>
            <span className={cx('text-danger', !props.big && 'small')}>
                {props.text ?? props.children}
            </span>
        </div>
    );
};
