import React from 'react';

export const Bookshelf: React.FC<React.SVGProps<SVGSVGElement>> = (props) => {
    return (
        <svg viewBox="0 0 48 48" {...props}>
            <path d="M5 1h8v30H5z" fill="#673ab6" />
            <path d="M5 1h3v30H5z" fill="#45279f" />
            <path d="m15 1h8v30h-8z" fill="#5cae50" />
            <path d="m15 1h3v30h-3z" fill="#388d3c" />
            <path d="m23 10h8v21h-8z" fill="#f34336" />
            <path d="m23 10h3v21h-3z" fill="#d22f2f" />
            <path d="M31 11l7.6-2.472L45.1 28.5l-7.6 2.472z" fill="#02bcd3" />
            <path d="M31 11l2.853-.927 6.5 19.972-2.853.927z" fill="#0399a9" />
            <path d="M0 31h48v6H0z" fill="#fe9f00" />
            <path d="M5 37h4v9H5zm34 0h4v9h-4z" fill="#ee6c00" />
            <g fill="#fff" fillOpacity=".4">
                <path d="M5 5h8v3H5zm0 19h8v3H5zM15 5h8v3h-8zm0 19h8v3h-8z" />
                <path d="M23 25h8v3h-8zm0-12h8v3h-8zm8.925.853l7.608-2.472.927 2.853-7.608 2.472zm3.7 11.417l7.608-2.472.927 2.853-7.608 2.472z" />
            </g>
        </svg>
    );
};
