import React from 'react';
import cx from 'classnames';

import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';

import { LoadingOverlay } from 'components/loadingOverlay';

export type DeleteEventListener = () => boolean | Promise<boolean>;

export interface FormFunctionParams<T> {
    editData?: T;
    onSubmitSuccess: (data: T) => void;
    onSubmitError: () => void;
    onSubmitStart: () => void;
    subscribeToDeleteEvent: (fn: DeleteEventListener) => void;
}

export interface FormFunctionReturnType {
    node: React.ReactElement;
    handlesDelete?: boolean;
    formId: string;
}

export type FormFunction<T> = (
    config: FormFunctionParams<T>,
) => FormFunctionReturnType;

interface ModalFormProps<T> {
    editData: T | undefined;
    formFunction: (config: FormFunctionParams<T>) => FormFunctionReturnType;
    show: boolean;
    title: string;
    onClose: () => void;
    onSubmitSuccess: (data: T) => void;
    onDelete?: () => void;
}

interface ModalFormState {
    loading: boolean;
}

export class ModalForm<T> extends React.Component<
    ModalFormProps<T>,
    ModalFormState
> {
    constructor(props: ModalFormProps<T>) {
        super(props);
        this.state = {
            loading: false,
        };
    }

    onSubmitStart = () => {
        this.setState({ loading: true });
    };

    onSubmitError = () => {
        this.setState({ loading: false });
    };

    onSubmitSuccess = (data: T) => {
        this.setState({ loading: false });
        this.props.onSubmitSuccess(data);
    };

    deleteEventListener: DeleteEventListener | null = null;

    subscribeToDeleteEvent = (fn: DeleteEventListener) => {
        this.deleteEventListener = fn;
    };

    onDelete = async () => {
        if (!this.deleteEventListener) {
            return;
        }

        this.setState({ loading: true });
        if (await Promise.resolve(this.deleteEventListener())) {
            this.props.onDelete?.();
        }
        this.setState({ loading: false });
    };

    render() {
        const { show, title, formFunction, onClose, editData } = this.props;

        const { node: form, handlesDelete, formId } = formFunction({
            editData: editData,
            onSubmitSuccess: this.onSubmitSuccess,
            onSubmitError: this.onSubmitError,
            subscribeToDeleteEvent: this.subscribeToDeleteEvent,
            onSubmitStart: this.onSubmitStart,
        });

        return (
            <Modal show={show} onHide={onClose} backdrop="static">
                <Modal.Header closeButton={!this.state.loading}>
                    <Modal.Title>{title}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {form}
                    <LoadingOverlay
                        show={this.state.loading}
                        variant="primary"
                        animation="border"
                    />
                </Modal.Body>
                <Modal.Footer>
                    {handlesDelete && (
                        <Button
                            disabled={this.state.loading}
                            variant="danger"
                            onClick={this.onDelete}
                            className={cx('mr-auto')}
                        >
                            Delete
                        </Button>
                    )}
                    <Button
                        disabled={this.state.loading}
                        variant="secondary"
                        onClick={onClose}
                    >
                        Cancel
                    </Button>
                    <Button
                        disabled={this.state.loading}
                        variant="primary"
                        type="submit"
                        form={formId}
                    >
                        Submit
                    </Button>
                </Modal.Footer>
            </Modal>
        );
    }
}
