import React, { useState } from 'react';
import cx from 'classnames';
import { Redirect } from 'react-router-dom';
import { useLocation } from 'react-router';
import { useForm } from 'react-hook-form';

import Form from 'react-bootstrap/Form';
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';

// import styles from 'styles/login.module.scss';
import { login, AuthError, isAuthenticated } from 'utils/store';
import { ErrorMessage } from 'components/errorMessage';
import { LoadingOverlay } from 'components/loadingOverlay';

interface LocationState {
    from: string;
}
interface Inputs {
    email: string;
    password: string;
}

export const Login: React.FC = () => {
    const [success, setSuccess] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false);
    const [error, setError] = useState<string>();

    const { register, handleSubmit } = useForm<Inputs>();

    let location = useLocation<LocationState>();

    const onSubmit = async (inputs: unknown) => {
        const { email, password } = inputs as Inputs;
        let auth_error: AuthError;
        setLoading(true);
        try {
            auth_error = await login(email, password);
        } catch {
            auth_error = AuthError.Unknown;
        }
        setLoading(false);
        switch (auth_error) {
            case AuthError.AccountDissabled:
                setError('Lo sentimos, su cuenta ha sido desabilitada');
                break;
            case AuthError.InvalidEmail:
                setError('El correo que ha ingresado no es valido');
                break;
            case AuthError.Unknown:
                setError(
                    'Ha ocurrido un error al intentar iniciar sesión, favor de intentarlo más tarde',
                );
                break;
            case AuthError.WrongCredentials:
                setError('El usuario o contraseña son incorrectos');
                break;
            case AuthError.None:
                setError(undefined);
                setSuccess(true);
                break;
        }
    };

    if (success || isAuthenticated()) {
        return <Redirect to={location.state?.from ?? '/'} />;
    }

    return (
        <div className={cx('center-content', 'app-container')}>
            <Card body>
                <ErrorMessage show={!!error}>{error}</ErrorMessage>
                <Form onSubmit={handleSubmit(onSubmit)}>
                    <Form.Group controlId="email">
                        <Form.Label>Email</Form.Label>
                        <Form.Control
                            type="email"
                            placeholder="Email"
                            name="email"
                            ref={register({ required: true })}
                        />
                    </Form.Group>
                    <Form.Group controlId="password">
                        <Form.Label>Password</Form.Label>
                        <Form.Control
                            type="password"
                            placeholder="Password"
                            name="password"
                            ref={register({ required: true })}
                        />
                    </Form.Group>
                    <div className={cx('text-center')}>
                        <Button variant="primary" type="submit">
                            Login
                        </Button>
                    </div>
                </Form>
                <LoadingOverlay show={loading} animation="border" />
            </Card>
        </div>
    );
};
