import React, { useState, useEffect } from 'react';
import styled, { css } from 'styled-components';
import cx from 'classnames';

import Spinner from 'react-bootstrap/Spinner';
// eslint-disable-next-line no-shadow
import Image from 'react-bootstrap/Image';

import { MdErrorOutline } from 'react-icons/md';

import { NoChildren } from 'lib/types';

interface CoverProps extends NoChildren {
    loadingFn: () => Promise<string | null>;
    delayLoad?: boolean;
    className?: string;
    size?: string;
}

/**  Remember to always pass a function as returned from **useCallback** or it will load every single time */
export const Cover: React.FC<CoverProps> = ({
    loadingFn,
    delayLoad,
    className,
    size,
}) => {
    const [loading, setLoading] = useState<boolean>(true);
    const [imgError, setImgError] = useState<boolean>(false);
    const [imgSrc, setimgSrc] = useState<string | null>(null);

    useEffect(() => {
        const getImageUrl = async () => {
            const res = await loadingFn();

            if (!res) {
                onError();
                return;
            }

            setimgSrc(res);
        };

        if (!delayLoad) {
            void getImageUrl();
        }
    }, [delayLoad, loadingFn]);

    const onLoad = () => {
        setLoading(false);
    };

    const onError = () => {
        setImgError(true);
        setLoading(false);
    };

    size = size ?? '50px';

    return (
        <Container className={className} size={size}>
            {imgSrc && (
                <StyledImage
                    rounded
                    src={imgSrc}
                    onLoad={onLoad}
                    onError={onError}
                    $size={size}
                    $hide={loading || imgError}
                />
            )}
            {loading && (
                <Spinner animation="grow" className={cx('text-grey')} />
            )}
            {imgError && (
                <MdErrorOutline className={cx('text-danger')} size="1.5em" />
            )}
        </Container>
    );
};

//#region styled

const Container = styled.div<{ size: string }>`
    width: ${(props) => props.size};
    min-width: ${(props) => props.size};
    display: flex;
    align-items: center;
    justify-content: center;
`;

const StyledImage = styled(Image)<{ $size: string; $hide: boolean }>`
    width: ${(props) => props.$size};
    max-height: 100%;
    height: auto;
    object-fit: cover;
    ${(props) =>
        props.$hide &&
        css`
            display: none;
        `}
`;

//#endregion
