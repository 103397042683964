import firebase from 'firebase/app';
import { auth, firestore, storage } from 'utils/firebase';
import { v4 as uuidv4 } from 'uuid';
import LRUCache from 'lru-cache';

import {
    chapterDocPath,
    chapterPath,
    coverStoragePath,
    mangaDocPath,
    mangaPath,
    nonNull,
} from 'lib/utils';
import { authErrorType, Manga, Chapter, NewManga } from 'lib/types';
import { makeCachedToLocalStorage } from './utils';

//#region auth

const FirebaseUserKey = 'fb_user';

const DefaultStoreMetadata = { cacheControl: 'private, max-age=18000' };

const [
    tryGetFirebaseUser,
    setFirebaseUser,
    clearFirebaseUser,
] = makeCachedToLocalStorage<firebase.User>(FirebaseUserKey);

// eslint-disable-next-line no-shadow
export enum AuthError {
    AccountDissabled = 'AccountDissabled',
    InvalidEmail = 'InvalidEmail',
    WrongCredentials = 'WrongCredentials',
    Unknown = 'Unknown',
    None = 'None',
}

export const login = async (
    email: string,
    password: string,
): Promise<AuthError> => {
    try {
        const result = await auth.signInWithEmailAndPassword(email, password);
        const fb_user = result.user;
        setFirebaseUser(fb_user);
        if (!fb_user) {
            return AuthError.Unknown;
        }
        return AuthError.None;
    } catch (error) {
        const code = error.code as authErrorType;
        switch (code) {
            case 'auth/invalid-email':
                return AuthError.InvalidEmail;
            case 'auth/wrong-password':
            case 'auth/user-not-found':
                return AuthError.WrongCredentials;
            case 'auth/user-disabled':
                return AuthError.AccountDissabled;
            default:
                return AuthError.Unknown;
        }
    }
};

export const logOut = async () => {
    try {
        await auth.signOut();
        clearFirebaseUser();
        return true;
    } catch {
        return false;
    }
};

export const isAuthenticated = () => {
    return !!tryGetFirebaseUser();
};

//#endregion

//#region read

export const getManga = async (): Promise<Manga[]> => {
    try {
        const result = await firestore
            .collection(mangaPath)
            .orderBy('title')
            .get();
        return result.docs.map((d) => {
            const manga = d.data() as Manga;
            manga.uid = d.id;
            return manga;
        });
    } catch (error) {
        console.warn(error);
        return [];
    }
};

const cache = new LRUCache<string, Manga>({ max: 100 });

export const tryGetMangaByUID = async (uid: string, shallow = false) => {
    const cached = cache.get(uid);
    if (cached) {
        if (shallow) {
            return { ...cached, chapters: undefined };
        } else {
            if (cached.chapters) {
                return cached;
            }
            cached.chapters = await getChaptersForManga(uid);
            cache.set(uid, cached);
            return cached;
        }
    }

    try {
        const result = await firestore.doc(mangaDocPath(uid)).get();
        const manga = result.data() as Manga;
        manga.uid = uid;
        if (!shallow) {
            manga.chapters = await getChaptersForManga(uid);
        }
        cache.set(uid, manga);
        return manga;
    } catch (error) {
        console.warn(error);
        return null;
    }
};

const getChaptersForManga = async (uid: string) => {
    try {
        const chapters = await firestore
            .collection(chapterPath(uid))
            .orderBy('number', 'desc')
            .get();
        return chapters.docs.map((d) => {
            const ch = d.data() as Chapter;
            ch.uid = d.id;
            return ch;
        });
    } catch (e) {
        console.warn(e);
        return [];
    }
};

export const getMangaByUID = nonNull(tryGetMangaByUID);

const coverRegex = /\w{32}(\.\w+)?/;
export const getCoverUrl = async (cover: string): Promise<string | null> => {
    if (!coverRegex.test(cover)) {
        return cover;
    }
    try {
        return await storage.ref(coverStoragePath(cover)).getDownloadURL();
    } catch (error) {
        console.warn(error);
        return null;
    }
};

export const tryGetChapterByUID = async (manga_uid: string, uid: string) => {
    const cached = cache.get(manga_uid);
    if (cached?.chapters) {
        const res = cached.chapters.find((c) => c.uid === uid);
        if (res) {
            return res;
        }
    }

    try {
        const result = await firestore
            .doc(chapterDocPath(manga_uid, uid))
            .get();
        const chapter = result.data() as Chapter;
        chapter.uid = uid;
        return chapter;
    } catch (error) {
        console.warn(error);
        return null;
    }
};

//#endregion

//#region create

export const addManga = async (manga: NewManga): Promise<Manga | null> => {
    try {
        const result = await firestore.collection(mangaPath).add(manga);
        return { uid: result.id, ...manga };
    } catch (error) {
        console.warn(error);
        return null;
    }
};

export const addCover = (
    file?: File,
): [string | undefined, firebase.storage.UploadTask | undefined] => {
    if (!file) {
        return [undefined, undefined];
    }
    try {
        let uid = uuidv4().replace(/-/g, '');
        const extension = file.name.split('.');
        if (extension.length > 1) {
            uid = uid.concat('.', extension[extension.length - 1]);
        }
        const upload = storage
            .ref(coverStoragePath(uid))
            .put(file, DefaultStoreMetadata);
        return [uid, upload];
    } catch (error) {
        console.warn(error);
        return [undefined, undefined];
    }
};

//#endregion

//#region update

export const updateManga = async (manga: Manga) => {
    try {
        const { uid, ...manga_data } = manga;
        await firestore.doc(mangaDocPath(uid)).set(manga_data);
        cache.set(uid, manga);
        return true;
    } catch (error) {
        console.warn(error);
        return false;
    }
};

export const updateCover = (
    uid: string,
    file: File,
): firebase.storage.UploadTask => {
    return storage.ref(coverStoragePath(uid)).put(file, DefaultStoreMetadata);
};

//#endregion

//#region delete

export const deleteManga = async (uid: string) => {
    try {
        await firestore.doc(mangaDocPath(uid)).delete();
        return true;
    } catch (error) {
        console.warn(error);
        return false;
    }
};

export const deleteCover = async (uid?: string) => {
    if (!uid) {
        return true;
    }
    try {
        await storage.ref(coverStoragePath(uid)).delete();
        return true;
    } catch (error) {
        console.warn(error);
        return false;
    }
};

//#endregion
