import React, { useCallback } from 'react';
import styled from 'styled-components';
import cx from 'classnames';
import ReactJson from 'react-json-view';

import ButtonGroup from 'react-bootstrap/ButtonGroup';

import {
    RiCheckboxMultipleLine,
    RiCheckboxMultipleBlankLine,
} from 'react-icons/ri';

import { useAsyncLoad } from 'utils/useAsyncLoad';
import { LoadingOverlay } from 'components/loadingOverlay';
import { getCoverUrl, tryGetMangaByUID } from 'utils/store';
import { Link, useParams } from 'react-router-dom';
import { ErrorMessage } from 'components/errorMessage';
import { Cover } from 'components/cover';
import { ChapterCard } from 'components/chapterCard';
import { parserMap } from 'utils/parsers';
import { IconButton } from 'components/iconButton';
import { updateChapterRead, updateChaptersRead } from 'utils/cloud_functions';

interface MangaParams {
    id: string;
}

export const Manga: React.FC = () => {
    const { id } = useParams<MangaParams>();

    const loadFn = useCallback(async () => {
        return await tryGetMangaByUID(id);
    }, [id]);

    const [manga, loading, _error, setManga] = useAsyncLoad(loadFn);

    const coverLoadFn = useCallback(async () => {
        if (!manga?.cover) {
            return null;
        }
        return await getCoverUrl(manga?.cover);
    }, [manga]);

    if (!manga) {
        if (loading) {
            return <LoadingOverlay show={true} animation="grow" />;
        }
        return (
            <div className={'center-content'}>
                <ErrorMessage
                    text={
                        "An error happened while loading this manga, or it doesn't exist"
                    }
                    show
                />
            </div>
        );
    }

    const onChapterReadChanged = (index: number) => async (value: boolean) => {
        if (!manga.chapters) {
            return;
        }
        const chapter = manga.chapters[index];
        if (value) {
            for (index; index < manga.chapters.length; index++) {
                manga.chapters[index].read = value;
            }
        } else {
            for (index; index >= 0; index--) {
                manga.chapters[index].read = value;
            }
        }
        setManga({ ...manga });
        await updateChapterRead({
            manga_id: manga.uid,
            chapter_id: chapter.uid,
            value,
            retroactive: true,
        });
    };

    const markAllAsRead = async () => {
        await updateAllReadValues(true);
    };

    const markAllAsNotRead = async () => {
        await updateAllReadValues(false);
    };

    const updateAllReadValues = async (value: boolean) => {
        if (!manga.chapters) {
            return;
        }
        manga.chapters.forEach((ch) => {
            ch.read = value;
        });
        setManga({ ...manga });
        await updateChaptersRead({ manga_id: manga.uid, value });
    };

    return (
        <>
            <Header>
                <Cover loadingFn={coverLoadFn} size="150px" />
                <InfoContainer>
                    <Title>{manga.title}</Title>
                    <SourceContainer>
                        {manga.sources.map((s, i) => (
                            <React.Fragment key={`${s.parser}_g`}>
                                <Source key={s.parser}>
                                    <p>{parserMap[s.parser].name}</p>
                                    <ReactJson
                                        src={s.args}
                                        theme="summerfruit"
                                        name={null}
                                        enableClipboard={false}
                                        displayDataTypes={false}
                                        displayObjectSize={false}
                                        style={{ backgroundColor: 'initial' }}
                                    />
                                </Source>
                                {i + 1 < manga.sources.length && (
                                    <Divider key={`${s.parser}_d`} />
                                )}
                            </React.Fragment>
                        ))}
                    </SourceContainer>
                </InfoContainer>
            </Header>
            <ControllsContainer>
                <div>
                    Read:
                    <ButtonGroup size="sm" className="ml-2">
                        <Link
                            className={cx(
                                'btn btn-indigo',
                                !manga.chapters?.length && 'disabled',
                            )}
                            to={(location) =>
                                `${location.pathname}/${
                                    manga.chapters?.[manga.chapters.length - 1]
                                        ?.uid
                                }`
                            }
                        >
                            First
                        </Link>
                        <Link
                            className={cx(
                                'btn btn-indigo',
                                !manga.current_chapter && 'disabled',
                            )}
                            to={(location) =>
                                `${location.pathname}/${
                                    manga.chapters?.find(
                                        (c) =>
                                            c.number === manga.current_chapter,
                                    )?.uid
                                }`
                            }
                        >
                            Current
                        </Link>
                        <Link
                            className={cx(
                                'btn btn-indigo',
                                !manga.chapters?.length && 'disabled',
                            )}
                            to={(location) =>
                                `${location.pathname}/${manga.chapters?.[0]?.uid}`
                            }
                        >
                            Last
                        </Link>
                    </ButtonGroup>
                </div>
                <IconButton className="btn-indigo" onClick={markAllAsRead}>
                    <RiCheckboxMultipleLine />
                </IconButton>
                <IconButton className="btn-indigo" onClick={markAllAsNotRead}>
                    <RiCheckboxMultipleBlankLine />
                </IconButton>
            </ControllsContainer>
            <ChaptersContainer>
                {manga.chapters?.map((ch, i) => (
                    <ChapterCard
                        chapter={ch}
                        onReadChange={onChapterReadChanged(i)}
                        key={ch.uid}
                    />
                ))}
            </ChaptersContainer>
        </>
    );
};

//#region styled

const Header = styled.div`
    display: flex;
    padding: 1rem;
`;

const InfoContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: baseline;
`;

const Title = styled.h2`
    margin-bottom: 1rem;
`;

const SourceContainer = styled.div`
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-around;
`;

const Source = styled.div`
    padding: 0 10px;

    &:not(:last-child) {
        border-right: 1px solid whitesmoke;
    }
`;

const Divider = styled.div`
    width: 1px;
    height: 100%;
    background-color: whitesmoke;
`;

const ControllsContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;

    padding: 1rem 0;
`;

const ChaptersContainer = styled.div`
    max-width: 800px;
    margin-left: auto;
    margin-right: auto;
`;

//#endregion
