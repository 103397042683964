import { Parsers, Source } from 'lib/types';

export const readM: Source = {
    name: 'readM',
    getUrlBuilder: ({ manga_id }) => {
        return (chapter) => {
            return `https://readm.org/manga/${manga_id}/${chapter}/all-pages`;
        };
    },
    enum: Parsers.READM,
};

export const heroManhua: Source = {
    name: 'heroManhua',
    getUrlBuilder: ({ manga }) => {
        return (chapter) => {
            return `https://heromanhua.com/manga/${manga}/ch-${chapter}/`;
        };
    },
    enum: Parsers.HEROMANHUA,
};

export const astralLibrary: Source = {
    name: 'astralLibrary',
    getUrlBuilder: ({ manga }) => {
        return (chapter) => {
            return `https://www.astrallibrary.net/manga/${manga}/chapter-${chapter}/`;
        };
    },
    enum: Parsers.ASTRALLIBRARY,
};

export const nonStopScans: Source = {
    name: 'nonStopScans',
    getUrlBuilder: ({ manga }) => {
        return (chapter) => {
            return `https://www.nonstopscans.com/${chapter}-${manga}/`;
        };
    },
    enum: Parsers.NONSTOPSCANS,
};

export const zeroScans: Source = {
    name: 'zeroScans',
    getUrlBuilder: ({ manga }) => {
        return (chapter) => {
            return `https://zeroscans.com/comics/${manga}/1/${chapter}`;
        };
    },
    enum: Parsers.ZEROSCANS,
};

export const readManhua: Source = {
    name: 'readManhua',
    getUrlBuilder: ({ manga }) => {
        return (chapter) => {
            return `https://readmanhua.net/manga/${manga}/chapter-${chapter}/?style=list`;
        };
    },
    enum: Parsers.READMANHUA,
};

export const parserMap = {
    [Parsers.READM]: readM,
    [Parsers.HEROMANHUA]: heroManhua,
    [Parsers.ASTRALLIBRARY]: astralLibrary,
    [Parsers.NONSTOPSCANS]: nonStopScans,
    [Parsers.ZEROSCANS]: zeroScans,
    [Parsers.READMANHUA]: readManhua,
};
