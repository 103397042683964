import { makeCached } from 'lib/utils';

export const makeCachedToLocalStorage = <T>(
    key: string,
): [() => T | null, (v: T | null) => void, () => void] => {
    const [get, set] = makeCached(
        () => JSON.parse(localStorage.getItem(key) ?? 'null') as T | null,
    );
    return [
        get,
        (v: T | null) => {
            set(v);
            localStorage.setItem(key, JSON.stringify(v));
        },
        () => {
            set(null);
            localStorage.removeItem(key);
        },
    ];
};
