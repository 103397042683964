import React from 'react';
import styled from 'styled-components';

import { RiCheckboxBlankLine, RiCheckboxLine } from 'react-icons/ri';

import { NoChildren } from 'lib/types';

interface CheckProps extends NoChildren {
    value: boolean;
    onValueChanged: (value: boolean) => void;
    alignment?: 'center' | 'left' | 'right';
}
export const Check: React.FC<CheckProps> = ({
    value,
    onValueChanged,
    alignment,
}) => {
    alignment = alignment ?? 'center';

    const onClick = () => {
        onValueChanged(!value);
    };

    return (
        <CheckContainer onClick={onClick} $alignment={alignment}>
            {value ? <Checked /> : <Unchecked />}
        </CheckContainer>
    );
};

const Checked: React.FC = () => {
    return <RiCheckboxLine color="white" size="20px" />;
};

const Unchecked: React.FC = () => {
    return <RiCheckboxBlankLine color="white" size="20px" />;
};

const CheckContainer = styled.div<{ $alignment: string }>`
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: ${(props) => props.$alignment};
`;
