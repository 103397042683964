import React, { useCallback, useEffect } from 'react';
import { Link, useHistory, useParams } from 'react-router-dom';
import styled from 'styled-components';
import cx from 'classnames';

import Button from 'react-bootstrap/Button';
import Spinner from 'react-bootstrap/Spinner';

import { FiArrowUp, FiArrowRight, FiArrowLeft } from 'react-icons/fi';
import { HiOutlineHome } from 'react-icons/hi';

import { useAsyncLoad } from 'utils/useAsyncLoad';
import { LoadingOverlay } from 'components/loadingOverlay';
import { tryGetChapterByUID, tryGetMangaByUID } from 'utils/store';
import { ErrorMessage } from 'components/errorMessage';
import { parseChapter, updateChapterRead } from 'utils/cloud_functions';
import { parserMap } from 'utils/parsers';
import { emptyOrNull, isNonNull } from 'lib/utils';
import { useHotkeys } from 'react-hotkeys-hook';

interface ChapterParams {
    manga_id: string;
    id: string;
}

export const Chapter: React.FC = () => {
    const { id, manga_id } = useParams<ChapterParams>();

    // eslint-disable-next-line no-shadow
    const history = useHistory();

    const mangaLoadFn = useCallback(async () => {
        return await tryGetMangaByUID(manga_id);
    }, [manga_id]);

    const [manga, mangaLoading] = useAsyncLoad(mangaLoadFn);

    const loadFn = useCallback(async () => {
        return await tryGetChapterByUID(manga_id, id);
    }, [id, manga_id]);

    const [chapter, loading, chapterError, setChapter] = useAsyncLoad(loadFn);

    const imgLoadFn = useCallback(async () => {
        if (!manga || !chapter) {
            return null;
        }

        if (!emptyOrNull(chapter.images)) {
            return chapter.images;
        }

        const sources = [];
        for (const source of manga?.sources) {
            sources.push({
                url: parserMap[source.parser].getUrlBuilder(source.args)(
                    chapter.number,
                ),
                parser: source.parser,
            });
        }

        const res = await Promise.all(
            sources.map(({ url, parser }) => {
                return parseChapter({
                    manga_id: manga.uid,
                    id: chapter.uid,
                    url,
                    parser,
                });
            }),
        );

        return res.filter(isNonNull).shift();
    }, [chapter, manga]);

    const [images, loading_images, images_error] = useAsyncLoad(
        imgLoadFn,
        !manga || !chapter,
    );

    useEffect(() => {
        if (chapter && emptyOrNull(chapter.images) && images) {
            setChapter({ ...chapter, images });
        }
    }, [chapter, images, setChapter]);

    useHotkeys(
        'right,up,shift+up,left,down,shift+down',
        (event) => {
            event.preventDefault();
            switch (event.key) {
                case 'ArrowRight':
                    void goToNext();
                    break;
                case 'ArrowUp':
                    scrollUp(event.shiftKey);
                    break;
                case 'ArrowLeft':
                    goToPrevChapter();
                    break;
                case 'ArrowDown':
                    scrollDown(event.shiftKey);
                    break;
            }
        },
        [manga, chapter],
    );

    const goToNext = async () => {
        if (!manga || !chapter) {
            return;
        }
        history.push(chapter.next ?? '');
        await updateChapterRead({
            manga_id: manga.uid,
            chapter_id: chapter.uid,
            value: true,
        });
    };

    const goToPrevChapter = () => {
        if (!chapter?.prev) {
            return;
        }
        history.push(chapter.prev);
    };

    const getWindowHeight = () =>
        window.innerHeight ||
        document.documentElement.clientHeight ||
        document.body.clientHeight;

    const scrollUp = (toTop = true) => {
        if (toTop) {
            window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
        } else {
            const windowHeight = getWindowHeight();
            window.scrollBy({
                top: -windowHeight / 2,
                behavior: 'smooth',
            });
        }
    };

    const scrollDown = (full = false) => {
        const windowHeight = getWindowHeight();
        window.scrollBy({
            top: full ? windowHeight : windowHeight / 2,
            behavior: 'smooth',
        });
    };

    if (loading) {
        return (
            <LoadingOverlay
                show={true}
                animation="grow"
                variant="info"
                transparent
            />
        );
    }
    if (!chapter || chapterError) {
        return (
            <div className={'center-content'}>
                <ErrorMessage
                    text={
                        "An error happened while loading this chapter, or it doesn't exist"
                    }
                    show
                    big
                />
            </div>
        );
    }

    return (
        <>
            <Title>
                {mangaLoading && <Spinner variant="info" animation="border" />}
                {manga && (
                    <Link to={`/manga/${manga_id}`}>{manga.title}</Link>
                )}{' '}
                <span className={'text-muted'}>Chapter {chapter.number}</span>
            </Title>
            {/* Probably let every immage retain its og size instead of making them the same */}
            <ChapterContainer>
                <PagesContainer>
                    {chapter.images?.map((img, i) => (
                        <Page
                            src={img}
                            key={i}
                            alt={`page: ${i + 1}/${chapter.images?.length}`}
                        />
                    ))}
                </PagesContainer>
                <LoadingOverlay
                    show={loading_images}
                    animation="grow"
                    variant="info"
                    transparent
                />
                <ErrorMessage
                    text="An error happened while parsing this chapter, please try again later"
                    show={images_error}
                    big
                />
            </ChapterContainer>

            <ButtonsContainer>
                <FloatButton
                    $hide={!chapter.prev}
                    className={cx('btn-indigo')}
                    onClick={goToPrevChapter}
                >
                    <FiArrowLeft />
                </FloatButton>
                <FloatButton className={cx('btn-indigo')} onClick={goToNext}>
                    {chapter.next && <FiArrowRight />}
                    {!chapter.next && <HiOutlineHome />}
                </FloatButton>
                <FloatButton className={cx('btn-indigo')} onClick={scrollUp}>
                    <FiArrowUp />
                </FloatButton>
            </ButtonsContainer>
        </>
    );
};

//#region styled

const Title = styled.h1`
    a {
        color: var(--white);
        text-decoration: none !important;
    }
    span {
        font-size: 0.8em;
    }
    div {
        margin-bottom: 10px;
        vertical-align: middle;
    }
`;

const ChapterContainer = styled.div`
    display: flex;
    justify-content: center;
`;

const PagesContainer = styled.div`
    display: inline-flex;
    flex-direction: column;
`;

const Page = styled.img`
    width: 100%;
    height: auto;
`;

const btnSize = '40px';
const FloatButton = styled(Button)<{ $hide: boolean }>`
    display: ${(props) => (props.$hide ? 'none' : 'flex')};
    align-items: center;
    justify-content: center;

    height: ${btnSize};
    width: ${btnSize};
    border-radius: 50%;

    padding: 0;
`;

const ButtonsContainer = styled.div`
    position: fixed;
    bottom: 20px;
    right: 20px;

    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 5px;
`;

//#endregion
