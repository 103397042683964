import React from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';

import { PrivateRoute } from 'utils/privateRoute';

import 'styles/app.scss';

import { Home } from 'views/home';
import { Login } from 'views/login';
import { Manga } from 'views/manga';
import { Chapter } from 'views/chapter';
import { StyleSheetManager } from 'styled-components';
import { NavBar } from 'components/navBar';
import { NotFound } from 'views/notFound';

function App() {
    return (
        <StyleSheetManager
            disableVendorPrefixes={process.env.NODE_ENV === 'development'}
        >
            <Router>
                <NavBar />
                <Switch>
                    <PrivateRoute path="/" exact component={Home} />

                    <PrivateRoute
                        path="/manga/:manga_id/:id"
                        component={Chapter}
                    />
                    <PrivateRoute path="/manga/:id" component={Manga} />

                    <PrivateRoute path="/config" />
                    <PrivateRoute path="/config/:id" />

                    <Route path="/login" component={Login} />
                    <Route path="*" component={NotFound} />
                </Switch>
            </Router>
        </StyleSheetManager>
    );
}

export default App;
