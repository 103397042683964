import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { parseManga } from 'utils/cloud_functions';

import Form from 'react-bootstrap/Form';

import { ErrorMessage } from 'components/errorMessage';
import { FormFunctionParams } from 'components/modalForm';
import { Manga } from 'lib/types';

interface Inputs {
    url: string;
    parseChapters: boolean;
}

interface MangaImportFormProps extends FormFunctionParams<Manga> {}

export const MangaImportFormId = '__manga_import_form__';

export const MangaImportForm: React.FC<MangaImportFormProps> = (props) => {
    const { register, handleSubmit, errors } = useForm<Inputs>();

    const [errorMessage, setErrorMessage] = useState<string>();

    const onSubmit = async (inputs: unknown) => {
        props.onSubmitStart();
        const { url, parseChapters } = inputs as Inputs;

        const manga_result = await parseManga({ url, parseChapters });

        if (manga_result) {
            props.onSubmitSuccess(manga_result);
        } else {
            setErrorMessage(
                'An error ocurred while importing the manga, please try again later.',
            );
            props.onSubmitError();
        }
    };

    return (
        <Form onSubmit={handleSubmit(onSubmit)} id={MangaImportFormId}>
            <ErrorMessage show={!!errorMessage}>{errorMessage}</ErrorMessage>

            <Form.Group controlId="url">
                <Form.Label>Url</Form.Label>
                <Form.Control
                    type="text"
                    placeholder="Url"
                    name="url"
                    ref={register({ required: true })}
                />
                <ErrorMessage show={!!errors.url}>
                    The url is required
                </ErrorMessage>
            </Form.Group>
            <Form.Group controlId="parseChapters">
                <Form.Check
                    type="checkbox"
                    name="parseChapters"
                    label="Parse chapters"
                    defaultChecked={false}
                    ref={register()}
                />
            </Form.Group>
        </Form>
    );
};
