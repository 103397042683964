import { functions } from 'utils/firebase';
import {
    ParseChapterParams,
    ParseChapterResult,
    ParseChaptersParams,
    ParseChaptersResult,
    ParseMangaParams,
    ParseMangaResult,
    UpdateChapterReadParams,
    UpdateChapterReadResult,
    UpdateChaptersReadParams,
    UpdateChaptersReadResult,
} from 'lib/types';

const makeCloudFunction = <Params, Result extends {}>(name: string) => {
    const func = functions.httpsCallable(name);

    return async (params: Params): Promise<Result | null> => {
        try {
            const res = await func(params);
            if (!res.data) {
                return null;
            }
            return res.data as Result;
        } catch (e) {
            console.warn(e);
            return null;
        }
    };
};

const makeVoidCloudFunction = <Params, _Result extends void>(name: string) => {
    const func = functions.httpsCallable(name);

    return async (params: Params): Promise<void> => {
        try {
            await func(params);
        } catch (e) {
            console.warn(e);
        }
    };
};

export const parseChapter = makeCloudFunction<
    ParseChapterParams,
    ParseChapterResult
>('parseChapter');

export const parseChapters = makeCloudFunction<
    ParseChaptersParams,
    ParseChaptersResult[]
>('parseChapters');

export const parseManga = makeCloudFunction<ParseMangaParams, ParseMangaResult>(
    'parseManga',
);

export const updateChaptersRead = makeVoidCloudFunction<
    UpdateChaptersReadParams,
    UpdateChaptersReadResult
>('updateChaptersRead');

export const updateChapterRead = makeVoidCloudFunction<
    UpdateChapterReadParams,
    UpdateChapterReadResult
>('updateChapterRead');
