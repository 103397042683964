import React, { useState } from 'react';
import { useForm } from 'react-hook-form';

import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import {
    addManga,
    addCover,
    updateManga,
    updateCover,
    deleteManga,
    deleteCover,
} from 'utils/store';
import { ErrorMessage } from 'components/errorMessage';
import { FormFunctionParams } from 'components/modalForm';
import { NewManga, Manga } from 'lib/types';

interface Inputs extends Omit<NewManga, 'cover'> {
    cover: FileList;
}

interface MangaFormProps extends FormFunctionParams<Manga> {}

export const MangaFormId = '__manga_form__';

export const MangaForm: React.FC<MangaFormProps> = (props) => {
    const { editData: manga } = props;

    const { register, handleSubmit, errors } = useForm<Inputs>();

    const [errorMessage, setErrorMessage] = useState<string>();

    const onSubmit = async (inputs: unknown) => {
        props.onSubmitStart();
        const { cover, ...rest } = inputs as Inputs;
        let data: NewManga;
        const file = cover.item(0) ?? undefined;

        if (!manga) {
            const [cover_uid, upload_task] = addCover(file);
            data = { ...rest, cover: cover_uid };

            const [manga_result, _cover_result] = await Promise.all([
                addManga(data),
                upload_task,
            ]);

            if (manga_result) {
                props.onSubmitSuccess(manga_result);
            } else {
                setErrorMessage(
                    'An error ocurred while creating the manga, please try again later.',
                );
                props.onSubmitError();
            }
        } else {
            let update_task = null;
            let coverUid = manga.cover;

            if (coverUid) {
                if (file) {
                    update_task = updateCover(coverUid, file);
                }
            } else {
                const [uid, task] = addCover(file);
                update_task = task;
                coverUid = uid;
            }
            data = { ...rest, cover: coverUid };
            const [success, _update_success] = await Promise.all([
                updateManga({ uid: manga.uid, ...data }),
                update_task,
            ]);
            if (success) {
                props.onSubmitSuccess({
                    uid: manga.uid,
                    ...data,
                });
            } else {
                setErrorMessage(
                    'An error ocurred while updating the manga, please try again later.',
                );
                props.onSubmitError();
            }
        }
    };

    const onDelete = async () => {
        if (!manga) {
            return false;
        }
        const [res, _cover_res] = await Promise.all([
            deleteManga(manga.uid),
            deleteCover(manga.cover),
        ]);
        if (!res) {
            setErrorMessage(
                'An error ocurred while deleting the manga, please try again later.',
            );
            return false;
        }

        return true;
    };

    if (manga) {
        props.subscribeToDeleteEvent(onDelete);
    }

    return (
        <Form onSubmit={handleSubmit(onSubmit)} id={MangaFormId}>
            <ErrorMessage show={!!errorMessage}>{errorMessage}</ErrorMessage>

            <Form.Group controlId="title">
                <Form.Label>Title</Form.Label>
                <Form.Control
                    type="text"
                    placeholder="Title"
                    name="title"
                    defaultValue={manga?.title}
                    ref={register({ required: true })}
                />
                <ErrorMessage show={!!errors.title}>
                    The title is required
                </ErrorMessage>
            </Form.Group>

            <Form.Group controlId="cover">
                <Form.File
                    id="cover"
                    name="cover"
                    label="Cover"
                    ref={register}
                    accept="image/*"
                />
            </Form.Group>

            <Row>
                <Col>
                    <Form.Group controlId="current_chapter">
                        <Form.Label>Current Chapter</Form.Label>
                        <Form.Control
                            type="number"
                            placeholder="Current Chapter"
                            name="current_chapter"
                            defaultValue={manga?.current_chapter ?? 0}
                            ref={register}
                        />
                    </Form.Group>
                </Col>
                <Col>
                    <Form.Group controlId="last_chapter">
                        <Form.Label>Last Chapter</Form.Label>
                        <Form.Control
                            type="number"
                            placeholder="Last Chapter"
                            name="last_chapter"
                            defaultValue={manga?.last_chapter ?? 0}
                            ref={register}
                        />
                    </Form.Group>
                </Col>
            </Row>
        </Form>
    );
};
