export const makeCached = <T>(
    fn: () => T | null,
): [() => T | null, (v: T | null) => void] => {
    let value: T | null = null;
    return [
        () => {
            if (value === null) {
                const val = fn();
                value = val;
            }
            return value;
        },
        (v: T | null) => {
            value = v;
        },
    ];
};

export const solveUrl = (
    url: string | null | undefined,
    base: string,
): string => {
    if (!url) {
        return '';
    }
    return new URL(url.trim(), base).toString();
};

export const nonNull = <T, A extends unknown[]>(
    fn: (...args: A) => T | null,
) => {
    return (...args: A): T => {
        const val = fn(...args);
        if (val === null) {
            throw Error('Invalid null value');
        }
        return val;
    };
};

export const asyncNonNull = <T, A extends unknown[]>(
    fn: (...arsgs: A) => Promise<T | null>,
) => {
    return async (...args: A): Promise<T> => {
        const val = await fn(...args);
        if (val === null) {
            throw Error('Invalid null value');
        }
        return val;
    };
};

export const arrayWithoutNull = <T>(arr: (T | null)[]): T[] => {
    return arr.map((v) => {
        if (v === null) {
            throw new Error('Invalid null value in array');
        } else {
            return v;
        }
    });
};

export const isNonNull = <T>(val: T | null | undefined): val is T => {
    return val !== null && val !== undefined;
};

export const nonNullProperty = <T, K extends keyof T>(
    v: T,
    k: K,
): v is T & { [key in K]: NonNullable<T[K]> } => {
    return v[k] !== null && v[k] !== undefined;
};

export const chunks = <T>(data: T[], chunkSize: number): T[][] => {
    const result = [];
    let offset = 0;
    while (offset < data.length) {
        const size = Math.min(data.length - offset, chunkSize);
        result.push(data.slice(offset, offset + size));
        offset += size;
    }
    return result;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/explicit-module-boundary-types
export const isKeyOf = <T>(key: any, obj: T): key is keyof T => {
    return key in obj;
};

export const emptyOrNull = (arr: unknown[] | undefined | null) => {
    return !arr || !arr.length;
};

//#region paths

export const projectPath = 'manga_reader';
export const basePath = `${projectPath}/data`;

export const mangaPath = `${basePath}/manga`;
export const mangaDocPath = (uid: string): string => `${mangaPath}/${uid}`;

export const chapterPath = (uid: string): string =>
    `${mangaDocPath(uid)}/chapters`;
export const chapterDocPath = (manga_uid: string, uid: string): string =>
    `${chapterPath(manga_uid)}/${uid}`;

export const coverPath = `${projectPath}/covers`;
export const coverStoragePath = (uid: string): string => `${coverPath}/${uid}`;

//#endregion
